import BaseLayer from "../BaseLayer";
import {NoFlyShowCircle} from "../node/bussiness/noFlyZone/noFlyCircle";
import {NoFlyShowPolygon} from "../node/bussiness/noFlyZone/noFlyPolygon";

const userNoFlyZoneHook = () => {
    let map3dCollection, renderLayer;
    return (function() {
        // 初始化地图图层实例
        const setMap = (options) => {
            const {map3d, layerID} = options;
            map3dCollection = map3d;
            renderLayer = new BaseLayer({name: layerID || "noFlyZoneLayer"}); // 初始化绘制层
            map3dCollection.layerManager.add(renderLayer); // 添加到地图中
        };
        // 处理绘制图层数据并开始绘制
        const handlingNoFlyData = (options) => {
            const {show, data, noFlyType, overlayColor, overlayAlpha, polylineDash, 
                outLineColor, outLineWidth} = options;
            data && data.map(t => {
                const type = t.type;
                const id = t.id;
                const name = t.name;
                // 绘制之前先查找一次有无当前id的实体，若有则不需要进行绘制
                let entity = getEntityById(id);
                if (!entity) {
                    const coordinates = t.coordinates;
                    if (type === "Point" && t.properties && t.properties.radius) {
                        const radius = t.properties.radius;
                        const position = Cesium.Cartesian3.fromDegrees(...coordinates);
                        createNoFlyCircle({id, name, show, position, radius, noFlyType, overlayColor, overlayAlpha, 
                            polylineDash, outLineColor, outLineWidth});
                    } else if (type === "Polygon") {
                        let positions = [];
                        coordinates && coordinates[0].map(t => {
                            const position = Cesium.Cartesian3.fromDegrees(...t);
                            positions.push(position);
                        });
                        createNoFlyPolygon({id, name, show, positions, noFlyType, overlayColor, overlayAlpha, 
                            polylineDash, outLineColor, outLineWidth});
                    }
                }
            });
        };
        // 绘制圆形禁飞区
        const createNoFlyCircle = (options) => {
            let layer = new NoFlyShowCircle(options);
            renderLayer && renderLayer.addNode(layer);
        }
        // 绘制多边形面状禁飞区
        const createNoFlyPolygon = (options) => {
            let layer = new NoFlyShowPolygon(options);
            renderLayer && renderLayer.addNode(layer);
        };
        // 控制对应id实体的显示隐藏状态
        const changeShowEntity = (id, type) => {
            if (!id) return;
            let entity = getEntityById(id);
            if (!entity) return;
            entity.show = (type !== null && type !== undefined) ? type : !entity.show;
        };
        // 通过id获取对应实体
        const getEntityById = (id) => {
            if (!id) return;
            return renderLayer.getById(id);
        };
        const deleteEntityById = (id) => {
            if (!id) return;
            let entity = getEntityById(id);
            if (!entity) return;
            renderLayer.remove(entity);
        };
        // 定位到渲染的实体
        const locationToEntity = (id) => {
            if (!id) return;
            let range = 4000;
            const node = renderLayer.getNodeById(id);
            if (node && node.entity) {
                range = node.drawType === 'noFlyCircle' ? 4000 : 0;
                // map3dCollection.flyTo(node.entity, {
                //     offset: new Cesium.HeadingPitchRange(0, -90, range), // 设置heading,pitch和range
                //     duration: 1.5
                // });
                map3dCollection.zoomTo(
                    node.entity,
                    new Cesium.HeadingPitchRange(0, -90, range)
                );
            }
        };
        // 获取当前renderLayer层的实体数量
        const getRenderLayerEntityNum = () => {
            if (renderLayer) {
                return renderLayer.getAll()[0].length;
            }
            return 0;
        };
        // 单独清除当前renderLayer层的渲染实体
        const clearRenderLayer = () => {
            renderLayer && renderLayer.clear();
        };
        // 清除地图实例，图层实体
        const clearNoFlyZone = () => {
            renderLayer && renderLayer.clear();
            map3dCollection.layerManager.remove(renderLayer, true);
            renderLayer = null;
            map3dCollection = null;
        };
        return {
            setMap,
            handlingNoFlyData,
            createNoFlyCircle,
            createNoFlyPolygon,
            changeShowEntity,
            getEntityById,
            deleteEntityById,
            locationToEntity,
            getRenderLayerEntityNum,
            clearRenderLayer,
            clearNoFlyZone
        };
    })();
};

export default userNoFlyZoneHook;