var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "no-fly_cont-dj" },
    [
      _c("div", { staticClass: "no-fly_top" }, [
        _c("div", { staticClass: "title" }, [
          _c(
            "div",
            { staticClass: "title-text" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("noFlyZone.heightLimit")))]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    "popper-class": "sm-tooltip",
                    effect: "light",
                    placement: "bottom-start",
                  },
                },
                [
                  _c("div", {
                    attrs: { slot: "content" },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("noFlyZone.heightLimitExplain")),
                    },
                    slot: "content",
                  }),
                  _c("img", {
                    staticClass: "img-sm",
                    attrs: { src: "/img/icon/shuoming.svg" },
                  }),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "download" }, [
            _c("img", {
              staticClass: "icon-upload",
              attrs: { src: "/img/taskCenter/labelPage/上传.png" },
              on: { click: _vm.openUploadFile },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "search" },
          [
            _c(
              "el-input",
              {
                attrs: {
                  placeholder: _vm.$t("noFlyZone.noFlySearch"),
                  size: "mini",
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleSearch($event)
                  },
                },
                model: {
                  value: _vm.searchForm.searchVal,
                  callback: function ($$v) {
                    _vm.$set(_vm.searchForm, "searchVal", $$v)
                  },
                  expression: "searchForm.searchVal",
                },
              },
              [
                _c("i", {
                  staticClass: "search-icon el-input__icon el-icon-search",
                  attrs: { slot: "suffix" },
                  slot: "suffix",
                }),
              ]
            ),
            _c("el-cascader", {
              attrs: {
                options: _vm.areajson,
                props: { expandTrigger: "hover", checkStrictly: true },
                clearable: "",
                filterable: "",
                placeholder: _vm.$t("noFlyZone.noFlySearchProvinces"),
                size: "mini",
              },
              on: { change: _vm.handleSearch },
              model: {
                value: _vm.searchForm.provinces,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "provinces", $$v)
                },
                expression: "searchForm.provinces",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "no-fly_list" },
        [
          !_vm.noFlyData || !_vm.noFlyData.length
            ? _c("div", { staticClass: "no-data" }, [
                _c("i", { staticClass: "el-icon-folder-delete" }),
                _c("p", [_vm._v("暂无数据...")]),
              ])
            : _vm._e(),
          _vm._l(_vm.noFlyData, function (item, index) {
            return _c(
              "div",
              {
                key: item.id + index,
                staticClass: "no-fly_list-item",
                class: { active: _vm.chooseNoFlyItem.id === item.id },
                on: {
                  click: function ($event) {
                    return _vm.chooseNoFly(item, "selected")
                  },
                },
              },
              [
                _c("div", { staticClass: "item-label" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c("div", { staticClass: "item-icon" }, [
                  _c("i", {
                    staticClass: "el-icon-aim",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.locationItem(item)
                      },
                    },
                  }),
                  _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.deleteItemData(item)
                      },
                    },
                  }),
                ]),
              ]
            )
          }),
        ],
        2
      ),
      _c("AddNoFlyPop", {
        ref: "noFlyUploadFile",
        on: { upLoadEmit: _vm.uploadFiles },
      }),
      _vm.isShowInformation
        ? _c("GeneralInformation", {
            attrs: { informationData: _vm.informationData },
            on: {
              closeInformation: _vm.closeInformationPop,
              changeValue: _vm.updateInformation,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }