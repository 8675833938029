<template>
    <!-- 限高 -->
    <div class="no-fly_cont-dj">
        <div class="no-fly_top">
            <div class="title">
                <div class="title-text">
                    <span>{{$t('noFlyZone.heightLimit')}}</span>
                    <el-tooltip class="item" popper-class="sm-tooltip" effect="light" placement="bottom-start">
                        <div slot="content" v-html="$t('noFlyZone.heightLimitExplain')"></div>
                        <img class="img-sm" src="/img/icon/shuoming.svg" />
                    </el-tooltip>
                </div>
                <div class="download">
                    <img class="icon-upload" src="/img/taskCenter/labelPage/上传.png" @click="openUploadFile" />
                    <!-- <img class="icon-download" src="/img/taskCenter/labelPage/下载.svg" /> -->
                </div>
            </div>
            <div class="search">
                <el-input @keyup.enter.native="handleSearch" :placeholder="$t('noFlyZone.noFlySearch')" size="mini" 
                    clearable v-model="searchForm.searchVal">
                    <i slot="suffix" class="search-icon el-input__icon el-icon-search"></i>
                </el-input>
                <el-cascader @change="handleSearch" :options="areajson" v-model="searchForm.provinces" 
                    :props="{expandTrigger: 'hover', checkStrictly : true}" 
                    clearable filterable :placeholder="$t('noFlyZone.noFlySearchProvinces')" size="mini" />
            </div>
        </div>
        <div class="no-fly_list">
            <div class="no-data" v-if="!noFlyData || !noFlyData.length">
                <i class="el-icon-folder-delete" />
                <p>暂无数据...</p>
            </div>
            <div class="no-fly_list-item" :class="{'active': chooseNoFlyItem.id === item.id}" 
                v-for="(item, index) in noFlyData" :key="item.id + index" @click="chooseNoFly(item, 'selected')">
                <div class="item-label">{{item.name}}</div>
                <div class="item-icon">
                    <i class="el-icon-aim" @click.stop="locationItem(item)" />
                    <!-- <img class="icon-download" src="/img/taskCenter/labelPage/下载.svg" alt=""> -->
                    <i class="el-icon-delete" @click.stop="deleteItemData(item)" />
                </div>
            </div>
        </div>
        <!-- 新增上传禁飞区弹窗 -->
        <AddNoFlyPop ref="noFlyUploadFile" @upLoadEmit="uploadFiles" />
        <!-- 右侧弹窗信息 -->
        <GeneralInformation v-if="isShowInformation" :informationData="informationData" 
            @closeInformation="closeInformationPop" @changeValue="updateInformation" />
    </div>
</template>

<script>
import areajson from '@/assets/mapJson/areajson';
import userNoFlyZoneHook from '@/components/cesium/js/hooks/noFlyZoneHook';
import NormalOperation from '@/components/cesium/js/interactive/operation/NormalOperation';
import {getNoFlyZoneList, getNoFlyZoneDetail, 
    deleteNoFlyZoneItem, addEditNoFlyZone, importNoFlyZone} from '@/api/noFlyZone/index';
import GeneralInformation from './components/generalInformation';
import AddNoFlyPop from './components/addNoFlyPop';

export default {
    name: 'heightLimitPage',
    components: {GeneralInformation, AddNoFlyPop},
    data() {
        return {
            // 省市下拉数据
            areajson: areajson.areajson,
            searchForm: {
                searchVal: '',
                provinces: ''
            },
            noFlyData: [],
            chooseNoFlyItem: {},
            isShowInformation: false,
            informationData: {}
        }
    },
    created() {
        this.$options.noFlyZoneHook = userNoFlyZoneHook();
        this.$nextTick(() => {
            const map3d = this.$parent.$refs.noFlyCusiumMap.$options.map3d;
            this.$options.noFlyZoneHook.setMap({map3d, layerID: 'temporary'});
            this.setEditModel(map3d);
            this.getNoFlyZoneData();
        });
    },
    methods: {
        getNoFlyZoneData() {
            let params = {
                flyZoneType: 3,
                name: this.searchForm.searchVal,
                provinceCode: '',
                cityCode: ''
            };
            if (this.searchForm.provinces && this.searchForm.provinces.length) {
                params.provinceCode = this.searchForm.provinces[0];
                if (this.searchForm.provinces.length > 1) {
                    params.cityCode = this.searchForm.provinces[1];
                }
            }
            this.noFlyData = [];
            this.closeInformationPop();
            getNoFlyZoneList(params).then(res => {
                if (res.data.code == 200) {
                    this.noFlyData = res.data.data || [];
                    this.createDrawLayer(this.noFlyData);
                }
            });
        },
        setEditModel(map3d) {
            map3d._container.style.cursor = "default";
            let afterFinish = (feature) => {
                if (!feature) return this.closeInformationPop();
                let id = feature && feature.id;
                id && this.chooseNoFly({id});
            };
            let params = {
                map: map3d,
                vueHandler: this,
                afterFinish,
                canEdit: false
            };
            map3d.triggerEditModel(new NormalOperation(params));
        },
        createDrawLayer() {
            let contents = [];
            this.noFlyData && this.noFlyData.map(t => contents.push({...t.content, id: t.id, name: t.name}));
            this.$options.noFlyZoneHook.handlingNoFlyData({
                noFlyType: 'heightLimit',
                show: true,
                data: contents,
                overlayColor: '#FFC300',
                overlayAlpha: 0.3,
                outLineColor: '#FFC300',
                outLineWidth: 0,
            });
        },
        handleSearch() {
            this.getNoFlyZoneData();
        },
        chooseNoFly(data, type) {
            if (this.chooseNoFlyItem.id === data.id) return;
            this.chooseNoFlyItem = data;
            getNoFlyZoneDetail({id: data.id}).then(res => {
                if (res.data.code == 200) {
                    this.informationData = res.data.data || {};
                    this.isShowInformation = true;
                }
            }).catch(() => {
                this.isShowInformation = false;
                this.informationData = {};
            });
            type && this.locationItem(data);
        },
        closeInformationPop() {
            this.isShowInformation = false;
            this.informationData = {};
            this.chooseNoFlyItem = {};
        },
        locationItem(data) {
            this.$options.noFlyZoneHook.locationToEntity(data.id);
        },
        deleteItemData(data) {
            this.$confirm('此操作将永久删除不可恢复, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteNoFlyZoneItem({ids: data.id}).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('删除成功!');
                        this.closeInformationPop();
                        let item = this.noFlyData.find(t => t.id === data.id);
                        if (item) {
                            let index = this.noFlyData.indexOf(item);
                            if (index !== -1) {
                                this.noFlyData.splice(index, 1);
                                this.$options.noFlyZoneHook.deleteEntityById(data.id);
                            }
                        }
                    }
                });
            });
        },
        updateInformation(data) {
            let params = JSON.parse(JSON.stringify(data));
            // 处理省市
            if (data.provinces && data.provinces.length) {
                let provincesItem = this.areajson.find(t => t.value === data.provinces[0]);
                params.provinceCode = data.provinces[0];
                if (provincesItem) {
                    params.province = provincesItem.label;
                }
                if (data.provinces.length > 1) {
                    params.cityCode = data.provinces[1];
                    if (provincesItem && provincesItem.children) {
                        let cityItem = provincesItem.children.find(t => t.value === data.provinces[1]);
                        if (cityItem) {
                            params.city = cityItem.label;
                        }
                    }
                }
            }
            delete params.provinces;
            delete params.content;
            addEditNoFlyZone(params).then(() => {});
        },

        openUploadFile() {
            this.$refs.noFlyUploadFile.handleOpenImport();
        },
        uploadFiles(data) {
            let formData = new FormData();
            formData.append('file', data.data.fileList[0]);
            formData.append('flyZoneType', 3);
            importNoFlyZone(formData).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导入成功!');
                    data.callback && data.callback();
                    this.getNoFlyZoneData();
                }
            }).finally(() => {
                this.$refs.noFlyUploadFile.loading = false;
            });
        }
    },
    beforeDestroy() {
        this.$options.noFlyZoneHook && this.$options.noFlyZoneHook.clearNoFlyZone();
        this.$options.noFlyZoneHook = null;
    }
}
</script>